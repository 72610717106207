.header {
  padding: 20px 15px;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    display: flex;
    align-items: center;
    font-family: $RMono;
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;
    .dark {
      color: #232323;
      margin-right: 5px;
    }
    .pink {
      color: #ec407a;
    }
  }
}
section {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  .item {
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
    .dataWrap {
      p {
        text-align: left;
        margin: 5px 0;
      }
      .data {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        .pic {
          display: flex;
          flex-direction: column;
          align-items: center;
          &:not(:last-child) {
            margin-right: 10px;
          }
          img {
            max-width: 100%;
          }
          p {
            text-align: center;
            max-width: 140px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

.mySlider {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 1500px) {
    max-width: 600px;
  }
  .pics {
    position: relative;
    min-height: 550px;
    @media screen and (max-width: 1500px) {
      min-height: 350px;
    }
    @media screen and (max-width: 550px) {
      min-height: 300px;
    }
    @media screen and (max-width: 430px) {
      min-height: 250px;
    }
    .progress {
      position: absolute;
      top: 26%;
      left: 36%;
      width: 130px !important;
      height: 130px !important;
    }
    img {
      width: 100%;
      position: absolute;
      z-index: 1;
      opacity: 0;
      &.loading {
        opacity: 0 !important;
      }
      &.active {
        z-index: 10;
        opacity: 1;
      }
    }
  }
  .action {
    position: relative;
    z-index: 20;
  }
}
