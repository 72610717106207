$RMono: "Roboto Mono", monospace;
.malocclusionPage {
  @import "../stylesForSingle.scss";
  background: linear-gradient(#fff 0%, #b9b9b9 30% 70%, #fff 100%);
  // .header {
  //   flex-direction: column;
  //   align-items: flex-start;
  //   .logo {
  //     margin-bottom: 20px;
  //   }
  // }
}
